import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/next@14.2.16_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/node_modules/.pnpm/next@14.2.16_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedText"] */ "/home/runner/work/sapling/sapling/packages/www/src/app/AnimatedText.client.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-girl-opening-window-blinds.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-man-and-a-boy.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-miniature-house-and-key.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/a-woman-having-coffee.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/sapling/sapling/packages/www/src/images/krenk-video-poster.jpg");
